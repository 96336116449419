<template lang="pug">
  div

    h1 {{ title }}

    div.filters
      InputGroup(label="Start Date"): DatePicker(v-model="filters.start_date", :presets="false", :append-icon="false", :min-date="minDate", :max-date="maxDate", @input="onStartDateChange")
      InputGroup(label="End Date"): DatePicker(v-model="filters.end_date", :presets="false", :append-icon="false", :min-date="minDate", :max-date="maxDate", @input="onEndDateChange")
      InputGroup(label="By collection or folder")
        AutoComplete.filters__filter-by(
          v-model="filteredBy.name",
          endpoint="collections/search",
          :append-icon="appendIcon",
          @input="clearFilterBy",
          @selected="filterByCollectionOrFolder"
        )
          template(v-slot:prepend)
            Icon(:icon="prependIcon", :prefix="prependIconPrefix", :style="filteredBy.type === 'partner' ? `color: ${brandColor};` : ''")
          template(v-slot:result="{item, query}")
            Icon(:icon="item.result_type === 'collection' ? 'collection' : 'folder'", :prefix="item.result_type === 'collection' ? 'far' : 'fas'", :style="item.type === 'partner' ? `color: ${brandColor};` : ''")
            span(v-html="$options.filters.highlight(item.label, query)")

    h2.section-title Content Performance

    Card(title="Content Clicks")
      AnalyticsChart(type="line", :endpoint="`analytics/content/content-clicks`", :params="filters")

    DataTable(title="Top Performing Collections", :endpoint="`analytics/content/top-performing-collections`", :perPage="10", :params="filters", :sort="{'total_clicks': 'desc'}")
      template(v-slot:rows="rows")
        DataTableRow(v-for="(row, i) in rows", :key="i", :row="row")
          DataTableColumn(title="Collection"): a(@click="updateUri({ collection_id: row.id, folder_id: null })") {{ row.name }}
          DataTableColumn(title="Folder"): a(@click="updateUri({ collection_id: null, folder_id: row.folder_id })") {{ row.folder_name }}
          DataTableColumn(title="Views", sortable="total_hits", width="max") {{ row.total_views }} #[span(v-show="row.views_percentage") ({{ row.views_percentage }}%)]
          DataTableColumn(title="Clicks", sortable="total_clicks", width="max") {{ row.total_clicks }}
          DataTableColumn(title="CTR (% Clicks/Views)", width="max") {{ row.total_views ? row.ctr + '%' : '-' }}

    DataTable(title="Top Performing Content", :endpoint="`analytics/content/top-performing-content`", :perPage="10", :params="filters", :sort="{'total_clicks': 'desc'}")
      template(v-slot:rows="rows")
        DataTableRow(v-for="(row, i) in rows", :key="i", :row="row")
          DataTableColumn(title="Content", :class="`col-block-type col-block-type--${row.content.block_type}`")
            component(:is="getComponent(row.content.block_type)", v-bind="row.content")
          DataTableColumn(title="Collection(s)", width="200px")
            div.result-popover
              a.result-popover-link() {{ row.collections.length }} {{ row.collections.length === 1 ? 'collection' : 'collections' }}
              div.result-popover-body
                p Currently in #[strong {{ row.collections.length }}] {{ row.collections.length === 1 ? 'collection' : 'collections' }}:
                ul
                  li(v-for="(collection, i) in row.collections", :key="i")
                    router-link(:to="{name: 'collection', params: {id: collection.id}}", target="_blank", :class="`collection-${collection.type}`")
                      Icon(icon="collection", :style="collection.type === 'partner' ? `color: ${brandColor};` : ''")
                      span {{ collection.name }}
          DataTableColumn(title="Folder(s)", width="200px")
            div.result-popover
              a.result-popover-link() {{ row.folders.length }} {{ row.folders.length === 1 ? 'folder' : 'folders' }}
              div.result-popover-body
                p Currently in #[strong {{ row.folders.length }}] {{ row.folders.length === 1 ? 'folder' : 'folders' }}:
                ul
                  li(v-for="(folder, i) in row.folders", :key="i")
                    router-link(:to="{name: 'collection.folder', params: {id: folder.id}}", target="_blank", :class="`folder-${folder.type}`")
                      Icon(icon="folder", prefix="fas", :style="folder.type === 'partner' ? `color: ${brandColor};` : ''")
                      span {{ folder.name }}
          DataTableColumn(title="Views", sortable="total_hits", width="max") {{ row.total_views }}
          DataTableColumn(title="Clicks", sortable="total_clicks", width="max") {{ row.total_clicks }} #[span(v-show="row.clicks_percentage") ({{ row.clicks_percentage }}%)]
          DataTableColumn(title="CTR (% Clicks/Views)", width="max") {{ row.total_views ? row.ctr + '%' : '-' }}

    h2.section-title Collection Optimization

    div.collection-optimisation
      Card(title="Current Content Mix by Media Type")
        AnalyticsChart(type="pie", :endpoint="`analytics/content/content-by-media-type`", :params="filters")
      Card(title="Clicks by Media Type")
        AnalyticsChart(type="pie", :endpoint="`analytics/content/clicks-by-media-type`", :params="filters")
      Card(title="Current Content Mix by Subject Area")
        AnalyticsChart(type="pie", :endpoint="`analytics/content/content-by-subject-area`", :params="filters")
      Card(title="Clicks by Subject Area")
        AnalyticsChart(type="pie", :endpoint="`analytics/content/clicks-by-subject-area`", :params="filters")
      Card(title="Current Content Mix by Content Depth")
        AnalyticsChart(type="pie", :endpoint="`analytics/content/content-by-depth`", :params="filters")
      Card(title="Clicks by Content Depth")
        AnalyticsChart(type="pie", :endpoint="`analytics/content/clicks-by-content-depth`", :params="filters")
</template>

<script>
import { sub as dateSub } from "date-fns"
import { isEmpty } from "lodash-es"
import { mapGetters } from "vuex"
import AnalyticsChart from "./components/AnalyticsChart"
import SidebarWidget from "@/components/SidebarWidget"

const ContentBlock = () => import("@/components/Blocks/ContentBlock.vue")
const CTABlock = () => import("@/components/Blocks/CTABlock.vue")
const HeadingBlock = () => import("@/components/Blocks/HeadingBlock.vue")
const ImageBlock = () => import("@/components/Blocks/ImageBlock.vue")
const ItineraryBlock = () => import("@/components/Blocks/ItineraryBlock.vue")
const NoteBlock = () => import("@/components/Blocks/NoteBlock.vue")
const UnknownBlock = () => import("@/components/Blocks/UnknownBlock.vue")

function formatDate(date) {
  let month = date.getMonth() + 1
  let day = date.getDate()

  return [date.getFullYear(), (month > 9 ? "" : "0") + month, (day > 9 ? "" : "0") + day].join("-")
}

export default {
  watch: {
    $route: "setup"
  },
  components: {
    AnalyticsChart,
    SidebarWidget
  },
  metaInfo() {
    return { title: this.title, bodyAttrs: { class: "page-content-analytics" } }
  },
  data: () => ({
    title: "Content Analytics",
    filters: {
      start_date: formatDate(dateSub(new Date(), { months: 1, days: 1 })),
      end_date: formatDate(dateSub(new Date(), { days: 1 })),
      collection_id: null,
      folder_id: null
    },
    filteredBy: {},
    minDate: new Date("2015-01-01"),
    maxDate: new Date().getFullYear().toString()
  }),
  computed: {
    ...mapGetters({
      user: "auth/user"
    }),
    brandColor() {
      return this.user.get("partner.brand_color")
    },
    prependIcon() {
      if (this.filters.collection_id) return "collection"
      else if (this.filters.folder_id) return "folder"
      else return "search"
    },
    prependIconPrefix() {
      if (this.filters.folder_id) return "fas"
      else return "far"
    },
    appendIcon() {
      if (this.filters.collection_id || this.filters.folder_id) return "times"
      return null
    }
  },
  beforeMount() {
    this.setup()
  },
  methods: {
    async setup() {
      this.setRouteQuery()
      this.setFilters()

      let { collection_id, folder_id } = this.filters

      if (collection_id) {
        try {
          let response = await this.$api.get(`collections/${collection_id}`)
          let { id, type, name } = response.data.item
          this.filteredBy = { id, object: "collection", type, name }
        } catch (error) {
          this.updateUri({ collection_id: null })
        }
      } else if (folder_id) {
        try {
          let response = await this.$api.get(`folders/${folder_id}`)
          let { id, type, name } = response.data.item
          this.filteredBy = { id, object: "folder", type, name }
        } catch (error) {
          this.updateUri({ folder_id: null })
        }
      }
    },
    setRouteQuery() {
      let { start_date, end_date } = this.$route.query

      let query = {}
      if (!start_date) query.start_date = this.filters.start_date
      if (!end_date) query.end_date = this.filters.end_date

      if (!isEmpty(query)) {
        this.updateUri(query)
      }
    },
    setFilters() {
      let { collection_id, folder_id, start_date, end_date } = this.$route.query

      if (collection_id) this.filters.collection_id = Number(collection_id)
      if (folder_id) this.filters.folder_id = Number(folder_id)
      if (start_date) this.filters.start_date = start_date
      if (end_date) this.filters.end_date = end_date
    },
    filterByCollectionOrFolder(result) {
      let query

      if (result.result_type === "collection") {
        this.filters.collection_id = result.id
        this.filters.folder_id = null
        query = { collection_id: result.id, folder_id: null }
      } else if (result.result_type === "folder") {
        this.filters.collection_id = null
        this.filters.folder_id = result.id
        query = { collection_id: null, folder_id: result.id }
      }

      this.updateUri(query)
    },
    onStartDateChange(date) {
      this.updateUri({ start_date: date })
    },
    onEndDateChange(date) {
      this.updateUri({ end_date: date })
    },
    clearFilterBy() {
      if (this.filters.collection_id || this.filters.folder_id) {
        this.filters.collection_id = null
        this.filters.folder_id = null
        this.updateUri({ collection_id: null, folder_id: null })
      }
    },
    updateUri(query) {
      this.$router.replace({
        name: "content-analytics",
        query: Object.assign({}, this.$route.query, query)
      })
    },
    getComponent(type) {
      switch (type) {
        case "content":
          return ContentBlock
        case "cta":
          return CTABlock
        case "heading":
          return HeadingBlock
        case "image":
          return ImageBlock
        case "itinerary":
          return ItineraryBlock
        case "note":
          return NoteBlock
        default:
          return UnknownBlock
      }
    }
  }
}
</script>

<style>
@import "@syntax51/app-kit/assets/styles/mixins/form/_form-control.css";

.page-content-analytics {
  .col-block-type {
    @apply md:border-l-4;

    .ag-block {
      &__icon {
        @apply text-lg;
      }

      h2 {
        @apply text-base font-normal;
      }

      .content-item-description {
        @apply hidden;
      }

      .content-item-meta {
        @apply -mx-2 -mt-1 text-sm text-supplement;

        &-icon {
          @apply text-gray-500;
        }

        &-item {
          @apply mt-1 px-2;

          &:first-child {
            @apply hidden;
          }
        }
      }

      img {
        @apply h-8 w-10;
      }

      &--cta .ag-block__url {
        @apply mt-1 text-sm;
      }

      &--note p {
        @apply xl:max-w-sm xl:truncate;
      }
    }

    &--content {
      @apply border-l-blocks-content;

      .ag-block__icon {
        @apply text-blocks-content;
      }
    }

    &--cta {
      @apply border-l-blocks-cta;

      .ag-block__icon {
        @apply text-blocks-cta;
      }
    }

    &--heading {
      @apply border-l-blocks-heading;

      .ag-block__icon {
        @apply text-blocks-heading;
      }
    }

    &--image {
      @apply border-l-blocks-image;

      .ag-block__icon {
        @apply text-blocks-image;
      }
    }

    &--note {
      @apply border-l-blocks-note;

      .ag-block__icon {
        @apply text-blocks-note;
      }
    }
  }

  .section-title {
    @apply my-6 border-b-2 pb-3;
    @apply md:my-8;
    @apply lg:mt-12;
  }

  .card + .section-title {
    @apply lg:mt-20;
  }

  .filters {
    @apply md:my-6 md:-mx-3 md:flex;
    @apply lg:my-12;

    .form-inputgroup {
      @apply md:mx-3 md:mb-0;
    }
  }

  .filters__filter-by .form-autocomplete-results .icon {
    @apply mr-3 text-supplement;
  }

  .result {
    .ag-block {
      @apply border-l-4;
    }

    &-title {
      @apply flex;
    }

    &-icon {
      @apply mr-2 text-supplement;

      .fa-book-open {
        @apply text-sm;
      }
    }

    &-meta {
      @apply sm:flex sm:flex-wrap;
      @apply -mx-3 text-sm text-supplement;

      &-item {
        @apply mx-3 mt-2 flex items-center;
      }

      &-icon {
        @apply sm:mr-2 sm:w-auto;
        @apply mr-3 flex flex-shrink-0 items-center justify-center text-paragraph;
      }
    }

    &-popover {
      @apply relative;

      &-link:hover + .result-popover-body {
        @apply block;
      }

      &-body {
        @apply absolute left-0 z-10 hidden overflow-hidden overflow-y-auto rounded border bg-white shadow;
        max-height: 500px;
        top: 100%;
        width: 350px;

        > p {
          @apply mx-6 my-4;
        }

        li {
          @apply border-t border-dotted;
        }

        a {
          @apply flex px-6 py-4;

          &:hover {
            @apply bg-primary-50;
          }
        }

        .collection-personal .icon,
        .folder-personal .icon {
          @apply text-supplement;
        }

        .icon {
          @apply mr-3 mt-1;
        }
      }
    }
  }

  .collection-optimisation {
    @apply md:grid md:grid-cols-2 md:gap-8;

    .card + .card {
      @apply md:mt-0;
    }
  }
}
</style>
